<template>
  <div>
    <b-card
      no-body
      class="p-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <strong>{{ $t('AppPrevious.Viewer.previous') }} {{ viewer.previoId }}, {{ $t('AppPrevious.Viewer.houseGuide') }} {{ viewer.guiaHouse }}, {{ $t('AppPrevious.Viewer.masterGuide') }} {{ viewer.guiaMaster }}</strong>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <span class="font-weight-bold mr-1">{{ $t('AppPrevious.Viewer.selectAll') }}</span>
          <b-form-checkbox
            v-model="selectAll"
            class="mr-0"
            name="is-rtl"
            @change="onChangeSelect"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-button
            variant="outline-success"
            size="sm"
            @click="onDownloadAll"
          >
            <feather-icon
              icon="ArrowDownCircleIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            {{ $t('AppPrevious.Viewer.download') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-card-body>
        <b-row>
          <b-col
            v-for="item in viewer.previoVisorDocumentos"
            :key="item.guid"
            cols="12"
            md="4"
          >
            <div>
              <b-img
                v-if="imageExtensionsAllowed.includes(item.extension)"
                :src="resolveItemUrl(item)"
                width="250px"
                @click="onClickItem(item)"
              />
              <b-avatar
                v-if="!imageExtensionsAllowed.includes(item.extension)"
                class="mb-1 mr-1"
                :variant="`light-primary`"
                size="100"
              >
                <feather-icon
                  size="21"
                  icon="BoxIcon"
                />
              </b-avatar>
            </div>
            <div>
              <b-form-checkbox
                v-model="item.seleccionado"
                class="mr-0 mt-50"
                name="is-rtl"
              />
              <span
                class="font-weight-bold mr-1 cursor-pointer"
                @click="onClickItem(item)"
              >
                {{ item.nombre }}
              </span>
            </div>
          </b-col>
        </b-row>
        <!-- {{ viewer }} -->
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-preview-image"
      v-model="showImageModal"
      :title="imageSelected.nombre"
      size="xl"
    >
      <b-img
        :src="imageSelected.url"
        fluid-grow
        alt="Responsive image"
      />
      <template #modal-footer>
        <span />
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BImg,

  BButton,
  BAvatar,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import previousService from '@/services/previous.service'
import router from '@/router'
import { saveAs } from 'file-saver'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BImg,
    BAvatar,
  },
  setup() {
    /* Services */
    const {
      fetchPreviousViewerByGuid,
      fetchPreviousViewerDownload,
    } = previousService()
    const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`
    const imageExtensionsAllowed = ref([
      'png',
      'PNG',
      'jpeg',
      'JPEG',
      'jpg',
      'JPG',
    ])

    const viewer = ref({})
    const imagesBaseUrl = ref(`${API_URL}/Previos/Partidas/Fotos/`)
    const documentBaseUrl = ref(`${API_URL}/Previos/Documentos/`)
    const selectAll = ref(true)
    const showImageModal = ref(false)
    const imageSelected = ref({})

    fetchPreviousViewerByGuid(router.currentRoute.params.guid, data => {
      viewer.value = data
    })

    const onChangeSelect = () => {
      viewer.value.previoVisorDocumentos.forEach(e => { e.seleccionado = selectAll.value })
    }

    const resolveItemUrl = item => {
      if (item.tipo === 1) return `${documentBaseUrl.value}${item.guid}`
      if (item.tipo === 2) return `${imagesBaseUrl.value}${item.guid}`
      return ''
    }

    const onClickItem = item => {
      if (imageExtensionsAllowed.value.includes(item.extension)) {
        imageSelected.value = { nombre: `${item.nombre}.${item.extension}`, url: resolveItemUrl(item) }
        showImageModal.value = true
      } else {
        saveAs(resolveItemUrl(item), `${item.nombre}.${item.extension}`)
      }
    }

    const onDownloadAll = () => {
      fetchPreviousViewerDownload(viewer.value, data => {
        const fileName = `Previo_${viewer.value.previoId}_House_${viewer.value.guiaHouse}.zip`
        const file = new File([data], fileName)
        saveAs(file, fileName)
      })
    }

    return {
      imageExtensionsAllowed,
      viewer,
      imagesBaseUrl,
      documentBaseUrl,
      showImageModal,
      imageSelected,
      selectAll,
      onChangeSelect,
      onClickItem,
      resolveItemUrl,
      onDownloadAll,
    }
  },
}
</script>

<style>

</style>
